import Introduction from "../components/introduction";
import PortfolioCards from "../components/PortfolioCards";

export default function Home() {
  return (
    <>
      <Introduction />
      <PortfolioCards />
    </>
  );
}
